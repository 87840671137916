import foto0 from "./fotos/0.JPG";
import foto1 from "./fotos/1.JPG";
import foto2 from "./fotos/2.JPG";
import foto3 from "./fotos/3.JPG";
import foto4 from "./fotos/4.JPG";
import foto5 from "./fotos/5.JPG";
import foto6 from "./fotos/6.JPG";
import foto7 from "./fotos/7.JPG";
import foto8 from "./fotos/8.JPG";
import foto9 from "./fotos/9.JPG";
import foto10 from "./fotos/10.JPG";
import foto11 from "./fotos/11.JPG";
import foto12 from "./fotos/12.JPG";
import foto13 from "./fotos/13.JPG";
import foto14 from "./fotos/14.JPG";
import foto15 from "./fotos/15.JPG";
import foto16 from "./fotos/16.JPG";
import foto17 from "./fotos/17.JPG";
import foto18 from "./fotos/18.JPG";
import foto19 from "./fotos/19.JPG";
import foto20 from "./fotos/20.JPG";
import foto21 from "./fotos/21.JPG";
import foto22 from "./fotos/22.JPG";
import foto23 from "./fotos/23.JPG";
import foto24 from "./fotos/24.JPG";
import foto25 from "./fotos/25.JPG";
import foto26 from "./fotos/26.JPG";
import foto27 from "./fotos/27.JPG";
import foto28 from "./fotos/28.JPG";
import foto29 from "./fotos/29.JPG";
import foto30 from "./fotos/30.JPG";
import foto31 from "./fotos/31.JPG";
import foto32 from "./fotos/32.JPG";
import foto33 from "./fotos/33.JPG";
import foto34 from "./fotos/34.JPG";
import foto35 from "./fotos/35.JPG";
import foto36 from "./fotos/36.JPG";
import foto37 from "./fotos/37.JPG";
import foto38 from "./fotos/38.JPG";
import foto39 from "./fotos/39.JPG";
import foto40 from "./fotos/40.JPG";
import foto41 from "./fotos/41.JPG";
import foto42 from "./fotos/42.JPG";
import foto43 from "./fotos/43.JPG";
import foto44 from "./fotos/44.JPG";
import foto45 from "./fotos/45.JPG";
import foto46 from "./fotos/46.JPG";
import foto47 from "./fotos/47.JPG";
import foto48 from "./fotos/48.JPG";
import foto49 from "./fotos/49.JPG";
import foto50 from "./fotos/50.JPG";
import foto51 from "./fotos/51.JPG";
import foto52 from "./fotos/52.JPG";
import foto53 from "./fotos/53.JPG";
import foto54 from "./fotos/54.JPG";
import foto55 from "./fotos/55.JPG";
import foto56 from "./fotos/56.JPG";
import foto57 from "./fotos/57.JPG";
import foto58 from "./fotos/58.JPG";
import foto59 from "./fotos/59.JPG";
import foto60 from "./fotos/60.JPG";
import foto61 from "./fotos/61.JPG";
import foto62 from "./fotos/62.JPG";
import foto63 from "./fotos/63.JPG";
import foto64 from "./fotos/64.JPG";
import foto65 from "./fotos/65.JPG";
import foto66 from "./fotos/66.JPG";
import foto67 from "./fotos/67.JPG";
import foto68 from "./fotos/68.JPG";
import foto69 from "./fotos/69.JPG";
import foto70 from "./fotos/70.JPG";
import foto71 from "./fotos/71.JPG";
import foto72 from "./fotos/72.JPG";
import foto73 from "./fotos/73.JPG";


export const quizFotos = {
    foto0,
    foto1,
    foto2,
    foto3,
    foto4,
    foto5,
    foto6,
    foto7,
    foto8,
    foto9,
    foto10,
    foto11,
    foto12,
    foto13,
    foto14,
    foto15,
    foto16,
    foto17,
    foto18,
    foto19,
    foto20,
    foto21,
    foto22,
    foto23,
    foto24,
    foto25,
    foto26,
    foto27,
    foto28,
    foto29,
    foto30,
    foto31,
    foto32,
    foto33,
    foto34,
    foto35,
    foto36,
    foto37,
    foto38,
    foto39,
    foto40,
    foto41,
    foto42,
    foto43,
    foto44,
    foto45,
    foto46,
    foto47,
    foto48,
    foto49,
    foto50,
    foto51,
    foto52,
    foto53,
    foto54,
    foto55,
    foto56,
    foto57,
    foto58,
    foto59,
    foto60,
    foto61,
    foto62,
    foto63,
    foto64,
    foto65,
    foto66,
    foto67,
    foto68,
    foto69,
    foto70,
    foto71,
    foto72,
    foto73
};